import React, { createContext, useEffect, useReducer } from "react";

import SplashScreen from "src/components/SplashScreen";
import axios from "src/utils/axios";
import jwtDecode from "jwt-decode";
import dataStore from "src/store/index";
import countries from "src/static/countries";

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  company: null
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE": {
      const { isAuthenticated, user, company } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        company
      };
    }
    case "LOGIN": {
      const { user, company } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        company
      };
    }
    case "MAGICLOGIN": {
      const { user, company } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        company
      };
    }
    case "UPDATE": {
      const { user } = action.payload;
      
      return {
        ...state,
        isAuthenticated: true,
        user
      };      
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),  
  resetPassword: () => Promise.resolve(),
  createMagicLink: () => Promise.resolve(),
  magicLogin: () => Promise.resolve(),
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (emailAddress, password) => {
    const response = await axios.post("/api/account/login", {
      emailAddress,
      password
    });
    const { accessToken, user, company } = response.data;

    setSession(accessToken);
    dataStore.dispatch({ type: "RESET" });

    dispatch({
      type: "LOGIN",
      payload: {
        user,
        company
      }
    });
  };

  const magicLogin = async (key) => {
    const response = await axios.post("/api/account/validateMagicLink?key="+key);
    const { accessToken, user, company } = response.data;

    setSession(accessToken);
    dataStore.dispatch({ type: "RESET" });

    dispatch({
      type: "MAGICLOGIN",
      payload: {
        user,
        company
      }
    });
  };

  const createMagicLink = async (emailAddress) => {
    await axios.get("/api/account/createMagicLink?emailAddress=" + emailAddress);
  };

  const resetPassword = async emailAddress => {
    await axios.post("/api/account/resetPasswordEmail", {
      emailAddress
    });
  };

  const logout = () => {
    setSession(null);
    //window.fcWidget && window.fcWidget.destroy();
    dataStore.dispatch({ type: "RESET" });        
    dispatch({ type: "LOGOUT" });
  };

  const updateAvatar = (userId, avatar) => async dispatch => {
    const response = await axios.post("api/account/updateAvatar",{ userId, avatar },
      { headers: { "Content-Type": "application/json" }});
    const { user } = response.data;
    dispatch({
      type: "UPDATE",
      payload: {
        user
      }
    });
  };
  
  const deleteProfilePic = (userId)=>async dispatch=>{
    const response = await axios.post("api/account/deleteProfilePic",{userId});
    const { user } = response.data;

    dispatch({
      type: "UPDATE",
      payload: {
        user
      }
    });
  };
  

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/api/account/me");
          const user = response.data || {};
          const companyResponse = await axios.get("/api/account/company");
          const company = companyResponse.data || {};

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
              company
            }
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
              company: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            company: null
          }
        });
      }
    };

    initialize();
  }, []);

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        resetPassword,
        updateAvatar,
        deleteProfilePic,
        createMagicLink,
        magicLogin,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Gets the country object corresponding to the companies country
export const getCompanyCountry = company => {
  return countries.find(option => {
    if (
      option &&
      company &&
      company.address &&
      company.address.country &&
      ((option.text && option.text.toLocaleLowerCase() === company.address.country.toLocaleLowerCase()) ||
        (option.value && option.value.toLocaleLowerCase() === company.address.country.toLocaleLowerCase()))
    ) {
      return true;
    }
  });
};

export default AuthContext;
