import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";

const initialState = {
  users: [],
  isDeleteDialogOpen: false,
  selectedUserIds: null
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsers(state, action) {
      const users = action.payload;

      state.users = users;
    },
    selectUsersForDelete(state, action) {
      const { userIds } = action.payload;

      state.isDeleteDialogOpen = true;
      state.selectedUserIds = userIds;
    },
    createUser(state, action) {
      const user = action.payload;

      state.users = [...state.users, user];
    },
    updateUser(state, action) {
      const user = action.payload;

      state.users = _.map(state.users, _user => {
        if (_user.id === user.id) {
          return user;
        }

        return _user;
      });
    },
    twoFactorUpdated(state, action) {
      const { userId, twoFactorEnabled, updateCompanyPolicy } = action.payload;      
      state.users = _.map(state.users, _user => {
        if (updateCompanyPolicy) {
          _user.twoFactorEnabled = twoFactorEnabled;
          _user.twoFactorPolicy = twoFactorEnabled;          
        } else if (_user.id === userId) {
            _user.twoFactorEnabled = twoFactorEnabled;                      
          }
        return _user;
      });
    },
    deleteUsers(state, action) {
      const { userIds } = action.payload;

      state.users = _.reject(state.users, user => userIds.includes(user.id));
    },
    closeDialog(state) {
      state.isDeleteDialogOpen = false;
      state.selectedUserIds = null;
    }
  }
});

export const reducer = slice.reducer;

export const getUsers = () => async dispatch => {
  const response = await axios.get("/api/admin/users");

  dispatch(slice.actions.getUsers(response.data));
};

export const createUser = data => async dispatch => {
  const response = await axios.post("/api/admin/users/new", data);

  dispatch(slice.actions.createUser(response.data));
};

export const selectUsersForDelete = userIds => async dispatch => {
  dispatch(slice.actions.selectUsersForDelete({ userIds }));
};

export const updateUser = (userId, update) => async dispatch => {
  const response = await axios.post("/api/admin/users/update", {
    userId,
    update
  });

  dispatch(slice.actions.updateUser(response.data));
};

export const deleteUsers = userIds => async dispatch => {
  await axios.post("/api/admin/users/remove", {
    userIds
  });

  dispatch(slice.actions.deleteUsers({ userIds }));
};

export const updateTwoFactor = (userId, twoFactorEnabled) => async dispatch => {
  await axios.post("/api/admin/users/updateTwoFactor", {
    userId,
    twoFactorEnabled
  });

  dispatch(slice.actions.twoFactorUpdated({ userId, twoFactorEnabled, updateCompanyPolicy:false }));
};

export const setTwoFactor = (userId, twoFactorEnabled) => async dispatch => {
  await axios.post("/api/admin/users/setTwoFactorPolicy", {
    userId,
    twoFactorEnabled
  });

  dispatch(slice.actions.twoFactorUpdated({ userId, twoFactorEnabled, updateCompanyPolicy:true }));
};

export const closeDialog = () => dispatch => {
  dispatch(slice.actions.closeDialog());
};

export default slice;
