import _ from "lodash";
import axios from "src/utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import products from "src/static/products";

const initialState = {
  allLicenses: [],
  assignedLicenses: [],
  usedLicenses: [],
  isReleaseDialogOpen: false,
  selectedUsedLicenseIds: []
};

const slice = createSlice({
  name: "licenses",
  initialState,
  reducers: {
    getAllLicenses(state, action) {
      const allLicenses = action.payload;

      state.allLicenses = allLicenses;
    },
    getAssignedLicenses(state, action) {
      const assignedLicenses = action.payload;

      state.assignedLicenses = assignedLicenses;
    },
    assignLicenses(state, action) {
      const assignedLicenses = action.payload;

      state.assignedLicenses = assignedLicenses;
    },
    bulkAssignLicenses(state, action) {
      const assignedLicenses = action.payload;

      state.assignedLicenses = assignedLicenses;
    },
    releaseLicenses(state, action) {
      const assignedLicenses = action.payload;

      state.assignedLicenses = assignedLicenses;
    },
    getUsedLicenses(state, action) {
      const usedLicenses = action.payload;

      state.usedLicenses = usedLicenses;
    },
    selectUsedLicensesForRelease(state, action) {
      const licenseIds = action.payload;

      state.isReleaseDialogOpen = true;
      state.selectedUsedLicenseIds = licenseIds;
    },
    releaseUsedLicenses(state, action) {
      const licenseIds = action.payload;

      state.usedLicenses = _.reject(state.usedLicenses, license =>
        licenseIds.includes(license.usedLicenseId)
      );
    },
    closeDialog(state) {
      state.isReleaseDialogOpen = false;
      state.selectedUsedLicenseIds = null;
    }
  }
});

export const reducer = slice.reducer;

export const getAllLicenses = () => async dispatch => {
  const response = await axios.get("/api/licenses/all");
  dispatch(slice.actions.getAllLicenses(response.data));
};

export const getAssignedLicenses = () => async dispatch => {
  const response = await axios.get("/api/licenses/allAssigned");
  const productVariantIds = response.data.map(item => item.productId);
  // Filter response.data based on multiple conditions
  const filteredData = response.data.filter(item => {
    // Check if productVariantId exists in products and its variants.text includes "GeoSTORM"
    return item.availableVariants.every(variant => variant.type !== "Network") && // Check if licenseType is not "Network"
           productVariantIds.includes(item.productId) && // Check if productVariantId exists in productVariantIds
           products.some(product => (
             product.variants.some(variant => (
               variant.value === item.productId &&
               variant.text.includes('GeoSTORM')
             ))
           ));
  });
  dispatch(slice.actions.getAssignedLicenses(filteredData));
};

export const assignLicenses = (userIds, assignedProducts) => async dispatch => {
  const response = await axios.post("/api/licenses/assign", {
    userIds,
    assignedProducts
  });
  dispatch(slice.actions.assignLicenses(response.data));
};

export const bulkAssignLicenses = (addedUsers, removedUsers) => async dispatch => {
  const response = await axios.post("/api/licenses/bulkAssign", {
    addedUsers,
    removedUsers
  });
  dispatch(slice.actions.bulkAssignLicenses(response.data));
};

export const releaseLicenses = (userIds, assignedProducts) => async dispatch => {
  const response = await axios.post("/api/licenses/release", {
    userIds,
    assignedProducts
  });
  dispatch(slice.actions.releaseLicenses(response.data));
};

export const getUsedLicenses = () => async dispatch => {
  const response = await axios.get("/api/licenses/used");

  dispatch(slice.actions.getUsedLicenses(response.data));
};

export const selectUsedLicensesForRelease = licenseIds => async dispatch => {
  dispatch(slice.actions.selectUsedLicensesForRelease({ licenseIds }));
};

export const releaseUsedLicenses = licenseIds => async dispatch => {
  await axios.post("/api/licenses/used/remove", {
    licenseIds
  });

  dispatch(slice.actions.releaseUsedLicenses({ licenseIds }));
};

export const closeDialog = () => dispatch => {
  dispatch(slice.actions.closeDialog());
};

export default slice;
